import { cloneElement } from 'react';
import { Navigate } from 'react-router-dom';

import BrandPersonalityMilestone from 'Components/BrandPersonalityMilestone';
import { ColorPaletteMilestone } from 'Components/ColorPaletteMilestone';
import ContractForm from 'Components/ContractForm';
import DesignBriefForm from 'Components/DesignBriefForm';
import FilesList from 'Components/FilesList';
import MoodboardMilestone from 'Components/MoodboardMilestone';
// Please don't delete before the release - it keeps many global styles
// TODO: Remove after release
import ProposalForm from 'Components/ProposalForm';

import { FigmaPluginAuth } from 'Pages/FigmaPluginAuth';
import { AuthComplete } from 'Pages/FigmaPluginAuth/sub-routes/AuthComplete';
import { ConnectToFigma } from 'Pages/FigmaPluginAuth/sub-routes/ConnectToFigma';
import { ConnectToJira } from 'Pages/FigmaPluginAuth/sub-routes/ConnectToJira';
import { ConnectToSlack } from 'Pages/FigmaPluginAuth/sub-routes/ConnectToSlack';
import EmailVerification from 'Pages/FigmaPluginAuth/sub-routes/EmailVerification';
import { ExtensionAuthComplete } from 'Pages/FigmaPluginAuth/sub-routes/ExtensionAuthComplete';
import { FirstProject } from 'Pages/FigmaPluginAuth/sub-routes/FirstProject';
import ForgotPassword from 'Pages/FigmaPluginAuth/sub-routes/ForgotPassword';
import { GdocAuthComplete } from 'Pages/FigmaPluginAuth/sub-routes/GdocAuthComplete';
import { GoogleAuthPage } from 'Pages/FigmaPluginAuth/sub-routes/GoogleAuthPage';
import { JiraAuthComplete } from 'Pages/FigmaPluginAuth/sub-routes/JiraAuthComplete';
import { ManualTaskAuthComplete } from 'Pages/FigmaPluginAuth/sub-routes/ManualTaskAuthComplete';
import { ReviewerAuthComplete } from 'Pages/FigmaPluginAuth/sub-routes/ReviewerAuthComplete';
import { SlackAuthComplete } from 'Pages/FigmaPluginAuth/sub-routes/SlackAuthComplete';
import { TranscriptAuthComplete } from 'Pages/FigmaPluginAuth/sub-routes/TranscriptAuthComplete';
import { Finetuning } from 'Pages/Finetuning';
import { Home } from 'Pages/Home';
import { ProjectListing } from 'Pages/Home/sub-routes/ProjectListing';
import DashboardProjects from 'Pages/Home/sub-routes/ProjectListing/components/DashboardProjects';
import Landing from 'Pages/Landing';
import LandingReadonly from 'Pages/LandingReadonly/LandingReadonly';
import Onboarding from 'Pages/OnboardingScreen/Onboarding';
import { PaymentComplete } from 'Pages/PaymentComplete';
import { ProjectDetails } from 'Pages/ProjectDetails';
import { Board } from 'Pages/ProjectDetails/sub-routes/Board';
import { Feed } from 'Pages/ProjectDetails/sub-routes/Feed';
import { ProjectPayments } from 'Pages/ProjectDetails/sub-routes/ProjectPayments';
import { Workspace } from 'Pages/ProjectDetails/sub-routes/Workspace';
import { Settings } from 'Pages/Settings';
import { ConnectedApps } from 'Pages/Settings/sub-routes/ConnectedApps';
import { LanguageAndTimezone } from 'Pages/Settings/sub-routes/LanguageAndTimezone';
import { Profile } from 'Pages/Settings/sub-routes/Profile';
import { SlackAuth } from 'Pages/SlackAuth';

import { PrivacyPolicy } from 'StaticPages/PrivacyPolicy/PrivacyPolicy';
import { TermsAndConditions } from 'StaticPages/TermsAndConditions/TermsAndConditions';

import { ROUTES } from './route.constants';

// INFO: Current Router implementation -> https://miro.com/app/board/uXjVOkVCBpM=/?share_link_id=853982223132

const projectDetailsRoutes = {
  path: ROUTES.PROJECT_DETAILS(':projectSlug'),
  component: <ProjectDetails />,
  isPrivate: true,
  children: [
    {
      index: true,
      component: <Navigate to={'workspace'} />,
      isPrivate: true,
    },
    {
      path: ROUTES.FEED(':projectSlug'),
      component: <Feed />,
      isPrivate: true,
    },
    {
      path: ROUTES.BOARD(':projectSlug'),
      component: <Board />,
      isPrivate: true,
    },
    {
      path: ROUTES.WORKSPACE(':projectSlug'),
      component: <Workspace />,
      isPrivate: true,
      children: [
        {
          path: ROUTES.BRIEF(':projectSlug', ':milestoneId'),
          component: <DesignBriefForm />,
          isPrivate: true,
        },
        {
          path: ROUTES.PROPOSAL(':projectSlug', ':milestoneId'),
          component: <ProposalForm />,
          isPrivate: true,
        },
        {
          path: ROUTES.CONTRACT(':projectSlug', ':milestoneId'),
          component: <ContractForm />,
          isPrivate: true,
        },
        {
          path: ROUTES.COLOR_PALETTE(':projectSlug', ':milestoneId'),
          component: <ColorPaletteMilestone />,
          isPrivate: false,
        },
        {
          path: ROUTES.MOODBOARD(':projectSlug', ':milestoneId'),
          component: <MoodboardMilestone />,
          isPrivate: true,
        },
        {
          path: ROUTES.DESIGN_CONCEPTS(':projectSlug', ':milestoneId'),
          component: <FilesList />,
          isPrivate: true,
        },
        {
          path: ROUTES.PRESENTATION(':projectSlug', ':milestoneId'),
          component: <FilesList />,
          isPrivate: true,
        },
        {
          path: ROUTES.DELIVERY(':projectSlug', ':milestoneId'),
          component: <FilesList />,
          isPrivate: true,
        },
        {
          path: ROUTES.USER_INTERVIEW(':projectSlug', ':milestoneId'),
          component: <FilesList />,
          isPrivate: true,
        },
        {
          path: ROUTES.DESIGN_REVIEW(':projectSlug', ':milestoneId'),
          component: <FilesList />,
          isPrivate: true,
        },
        {
          path: ROUTES.SKETCHES(':projectSlug', ':milestoneId'),
          component: <FilesList />,
          isPrivate: true,
        },
        {
          path: ROUTES.BRAND_PERSONALITY(':projectSlug', ':milestoneId'),
          component: <BrandPersonalityMilestone />,
          isPrivate: true,
        },
      ],
    },
    {
      path: ROUTES.PAYMENTS(':projectSlug'),
      component: <ProjectPayments />,
      isPrivate: true,
    },
  ],
};

export const routes = [
  {
    path: ROUTES.LANDING,
    component: <Landing />,
  },
  {
    path: ROUTES.PAYMENT_COMPLETE,
    component: <PaymentComplete />,
    isPrivate: false,
  },
  {
    path: ROUTES.SLACK_AUTH_RETURN,
    component: <SlackAuth />,
    isPrivate: false,
  },
  {
    path: ROUTES.FIGMA_PLUGIN_AUTH,
    component: <FigmaPluginAuth />,
    isPrivate: false,
    children: [
      {
        index: true,
        component: <GoogleAuthPage />,
        isPrivate: false,
      },
      {
        path: ROUTES.VERIFY_EMAIL,
        component: <EmailVerification />,
        isPrivate: false,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        component: <ForgotPassword />,
        isPrivate: false,
      },
      {
        path: ROUTES.CREATE_NEW_PROJECT,
        component: <FirstProject />,
        isPrivate: true,
      },
      {
        path: ROUTES.CONNECT_TO_FIGMA,
        component: <ConnectToFigma />,
        isPrivate: true,
      },
      {
        path: ROUTES.CONNECT_TO_JIRA,
        component: <ConnectToJira />,
        isPrivate: true,
      },
      {
        path: ROUTES.CONNECT_TO_SLACK,
        component: <ConnectToSlack />,
        isPrivate: true,
      },
      {
        path: ROUTES.AUTH_COMPLETE,
        component: <AuthComplete />,
        isPrivate: true,
      },
    ],
  },
  {
    path: ROUTES.REVIEWER_AUTH_COMPLETE,
    component: <ReviewerAuthComplete />,
    isPrivate: true,
  },
  {
    path: ROUTES.EXTENSION_AUTH_COMPLETE,
    component: <ExtensionAuthComplete />,
    isPrivate: true,
  },
  {
    path: ROUTES.GDOC_AUTH_COMPLETE,
    component: <GdocAuthComplete />,
    isPrivate: true,
  },
  {
    path: ROUTES.MANUAL_TASK_AUTH_COMPLETE,
    component: <ManualTaskAuthComplete />,
    isPrivate: true,
  },
  {
    path: ROUTES.TRANSCRIPT_AUTH_COMPLETE,
    component: <TranscriptAuthComplete />,
    isPrivate: true,
  },
  {
    path: ROUTES.JIRA_AUTH_COMPLETE,
    component: <JiraAuthComplete />,
    isPrivate: true,
  },
  {
    path: ROUTES.SLACK_AUTH_COMPLETE,
    component: <SlackAuthComplete />,
    isPrivate: true,
  },
  {
    path: ROUTES.CLIENT_LOGIN,
    component: <Navigate to={ROUTES.LANDING} />,
  },
  {
    path: ROUTES.LANDING_READONLY(':readonlyUUID'),
    component: <LandingReadonly />,
  },
  {
    path: ROUTES.HOME,
    component: <Home />,
    isPrivate: true,
    children: [
      {
        index: true,
        component: <ProjectListing />,
        isPrivate: true,
      },
      {
        path: ROUTES.DASHBOARD,
        component: <ProjectListing />,
        isPrivate: true,
        children: [
          {
            index: true,
            component: <Navigate to={ROUTES.DASHBOARD_PROJECT_TYPE('active')} />,
            isPrivate: true,
          },
          {
            path: ROUTES.DASHBOARD_PROJECT_TYPE(':projectType'),
            component: <DashboardProjects />,
            isPrivate: true,
          },
        ],
      },
    ],
  },
  projectDetailsRoutes,
  {
    path: ROUTES.ON_BOARDING,
    component: <Onboarding />,
    isPrivate: true,
  },
  {
    path: ROUTES.SETTINGS,
    component: <Settings />,
    isPrivate: true,
    children: [
      {
        index: true,
        component: <Navigate to={ROUTES.PROFILE_SETTINGS} />,
        isPrivate: true,
      },
      {
        path: ROUTES.PROFILE_SETTINGS,
        component: <Profile />,
        isPrivate: true,
      },
      {
        path: ROUTES.CONNECTED_APPS_SETTINGS,
        component: <ConnectedApps />,
        isPrivate: true,
      },
      {
        path: ROUTES.LANGUAGE_AND_TIMEZONE_SETTINGS,
        component: <LanguageAndTimezone />,
        isPrivate: true,
      },
    ],
  },
  {
    path: ROUTES.TERMS_AND_CONDITIONS,
    component: <TermsAndConditions />,
    isPrivate: false,
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    component: <PrivacyPolicy />,
    isPrivate: false,
  },
  {
    path: ROUTES.SESSION_EXPIRED,
    component: <Landing attemptLogin sessionHasExpired />,
    isPrivate: false,
  },
  {
    path: ROUTES.FINETUNING,
    component: <Finetuning />,
    isPrivate: true,
  },
  {
    path: ROUTES.FINETUNING_REVIEW(':designReviewId'), // new route for a specific review
    component: <Finetuning />,
    isPrivate: true,
  },
];

export const readonlyRoutes = [
  ...routes
    .filter(({ isPrivate }) => !isPrivate)
    .map(changePrivateRouteToReadonly),
  changePrivateRouteToReadonly(projectDetailsRoutes),
];

function changePrivateRouteToReadonly(route) {
  const newRoute = {
    ...route,
    component: cloneElement(route.component, {
      isReadonly: true,
    }),
    isPrivate: false,
    isReadonly: true,
  };

  if (route.children) {
    newRoute.children = route.children.map(changePrivateRouteToReadonly);
  }

  return newRoute;
}
