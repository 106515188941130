import PropTypes from 'prop-types';
import { CheckCircleFilled, FileImageOutlined } from '@ant-design/icons';
import { Card, Divider, Tooltip, Typography } from 'antd';

import styles from './styles.module.scss';

const { Text, Title } = Typography;

export const DesignReviewBox = ({ metadata, onClick, reviewers, currentUser }) => {
  const {
    frame_image: frameImage,
    file_name: fileName,
    template,
    page_name: pageName,
    page_type: pageType,
    review_frame_name: reviewFrameName,
  } = metadata;

  const isReviewer = reviewers?.some(reviewer => reviewer.uuid === currentUser?.uuid) || false;

  return (
    <Card
      hoverable
      className={styles.reviewBox}
      cover={
        frameImage
          ? (
            <img
              alt={`${fileName} preview`}
              src={`https://firebasestorage.googleapis.com/v0/b/designpro-325314.appspot.com/o/${encodeURIComponent(frameImage)}?alt=media`}
              className={styles.image}
            />
          )
          : (
            <div className={styles.placeholder}>
              <FileImageOutlined style={{ fontSize: '2rem', color: '#aaa' }} />
            </div>
          )
      }
      onClick={onClick}
      aria-label={`Review of ${fileName}`}
    >
      <div className={styles.cardContent}>
        <Title level={5} className={styles.title}>
          {fileName}
          {isReviewer && (
            <Tooltip title="You have reviewed this design">
              <CheckCircleFilled style={{ color: 'green', marginLeft: 'auto' }} />
            </Tooltip>
          )}
        </Title>
        <Text type="secondary" className={styles.template}>
          {template || 'No template available'}
        </Text>
        <Divider className={styles.divider} />
        <div className={styles.metadata}>
          <Text>
            <b>
              Page Name:
            </b>
            {' '}
            {pageName || 'N/A'}
          </Text>
          <Text>
            <b>
              Page Type:
            </b>
            {' '}
            {pageType || 'N/A'}
          </Text>
          <Text>
            <b>
              Frame Name:
            </b>
            {' '}
            {reviewFrameName || 'N/A'}
          </Text>
        </div>
      </div>
    </Card>
  );
};

DesignReviewBox.propTypes = {
  metadata: PropTypes.shape({
    frame_image: PropTypes.string,
    file_name: PropTypes.string.isRequired,
    template: PropTypes.string,
    page_name: PropTypes.string,
    page_type: PropTypes.string,
    review_frame_name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  reviewers: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    uuid: PropTypes.string,
  })).isRequired,
  currentUser: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};
