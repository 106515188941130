import { ApiManager } from 'utlis/api_manager';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class FineTuningService {
  static async getDesignReviews(offset = 0, filter = 'All', pageSize = 12) {
    try {
      const params = {
        limit: pageSize,
        offset,
      };

      if (filter !== 'All') {
        params.template = filter;
      }

      const response = await api
        .setUrl('finetuning/design-reviews')
        .get(params);

      return response?.data;
    }
    catch (error) {
      if (error.response) {
        throw new Error(error.response.data?.message || 'Failed to fetch design reviews');
      }

      throw new Error('An unexpected error occurred');
    }
  }

  static async getDesignReviewJson(designReviewId, jsonPath) {
    try {
      const response = await api
        .setUrl(`finetuning/design-reviews/${designReviewId}/get_json`)
        .post({ json_path: jsonPath });

      return response?.data;
    }
    catch (error) {
      if (error.response) {
        throw new Error(error.response.data?.error || 'Failed to fetch JSON data');
      }

      throw new Error('An unexpected error occurred');
    }
  }

  static async getDesignReviewById(designReviewId) {
    try {
      const response = await api.setUrl(`finetuning/design-reviews/${designReviewId}`).get();

      return response?.data;
    }
    catch (error) {
      if (error.response) {
        throw new Error(error.response.data?.message || 'Failed to fetch design review');
      }

      throw new Error('An unexpected error occurred');
    }
  }

  static async submitFeedbackRating(designReviewId, feedbackItems) {
    try {
      const payload = {
        rated_feedback: feedbackItems.map(item => ({
          issue: item.issue,
          label: item.label,
          reason: item.reason,
          category: item.category,
          node_ids: item.node_ids,
          detailed_feedback: item.detailed_feedback,
          ratings: {
            accuracy: item.ratings.accuracy,
            relevance: item.ratings.relevance,
            actionable: item.ratings.actionable,
          },
          comment: item.ratings.comment,
          thumbs_up: item.ratings.thumbs_up,
          thumbs_down: item.ratings.thumbs_down,
          desired_feedback: item.ratings.desired_feedback,
        })),
      };

      const response = await api
        .setUrl(`finetuning/design-reviews/${designReviewId}/ratings`)
        .post(payload);

      return response?.data;
    }
    catch (error) {
      if (error.response) {
        throw new Error(error.response.data?.message || 'Failed to submit feedback ratings');
      }

      throw new Error('An unexpected error occurred');
    }
  }
}
