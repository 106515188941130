
import styles from './styles.module.scss';

export const SkeletonCard = () => (
  <div className={styles['skeleton-card']}>
    <div className={styles['skeleton-card__image']} />
    <div className={styles['skeleton-card__content']}>
      <div className={styles['skeleton-card__content-title']} />
      <div className={styles['skeleton-card__content-text']} />
      <div className={styles['skeleton-card__content-text']} />
      <div className={styles['skeleton-card__content-text']} />
      <div className={styles['skeleton-card__content-text']} />
      <div className={styles['skeleton-card__content-text']} />
    </div>
  </div>
);
