import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Pagination, Row } from 'antd';

import Logo from 'assets/finetuning/logo.png';

import FineTuningService from 'services/fineTuning';

import { DesignReviewBox } from './components/DesignReviewBox';
import { SingleDesignReview } from './components/SingleDesignReview';
import { SkeletonCard } from './components/SkeletonCard';

import styles from './styles.module.scss';

const FILTERS = ['All reviews', 'Improve copy', 'Audit UI', 'Accessibility', 'Conversions'];
const PAGE_SIZE = 12; // Matching the API's limit parameter

// Helper to convert UI filter names to API filter values
const getApiFilter = uiFilter => {
  const filterMap = {
    'All reviews': 'All',
    'Improve copy': 'Improve copy',
    'Audit UI': 'Audit UI',
    'Accessibility': 'Accessibility',
    'Conversions': 'Conversions',
  };

  return filterMap[uiFilter] || uiFilter;
};

export const Finetuning = () => {
  const { designReviewId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('All reviews');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const currentUser = JSON.parse(localStorage.getItem('userObject'));

  const fetchDesignReviews = async (page, filter) => {
    setLoading(true);
    console.log(data);

    try {
      const offset = (page - 1) * PAGE_SIZE;
      const apiFilter = getApiFilter(filter);
      const response = await FineTuningService.getDesignReviews(offset, apiFilter, PAGE_SIZE);

      setTotalItems(response.count);

      return response.results || [];
    }
    catch (apiError) {
      setError(apiError.message);

      return [];
    }
    finally {
      setLoading(false);
    }
  };

  const fetchSingleDesignReview = async id => {
    setLoading(true);

    try {
      const review = await FineTuningService.getDesignReviewById(id);

      setSelectedReview(review);
    }
    catch (singleError) {
      setError(singleError.message);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (designReviewId) {
      fetchSingleDesignReview(designReviewId);
    }
    else {
      fetchDesignReviews(currentPage, selectedFilter).then(results => {
        setData(results);
        setFilteredData(results);
      });
    }
  }, [designReviewId, currentPage]);

  const handleFilterChange = async filter => {
    setSelectedFilter(filter);
    setCurrentPage(1); // Reset to first page when filter changes

    const results = await fetchDesignReviews(1, filter);

    setFilteredData(results);
  };

  const handlePageChange = async page => {
    setCurrentPage(page);
    const results = await fetchDesignReviews(page, selectedFilter);

    setFilteredData(results);
    // Scroll to top of the page for better UX
    window.scrollTo(0, 0);
  };

  const handleReviewClick = review => {
    setSelectedReview(review);
    navigate(`/finetuning/${review.uuid}`);
  };

  if (selectedReview) {
    return (
      <SingleDesignReview
        designReview={selectedReview}
        onBack={() => setSelectedReview(null)}
        currentUser={currentUser}
      />
    );
  }

  return (
    <div className={styles['home-wrapper']}>
      <div className={styles['dashboard-header']}>
        <img className={styles['header-logo']} src={Logo} alt="design pro logo" />
        <h1>
          Dashboard
        </h1>
      </div>

      <div className={styles['filter-capsules']}>
        {FILTERS.map(filter => (
          <Button
            key={filter}
            type={selectedFilter === filter ? 'primary' : 'default'}
            onClick={() => handleFilterChange(filter)}
            className={styles['filter-button']}
          >
            {filter}
          </Button>
        ))}
      </div>

      {loading
        ? (
          <Row gutter={[16, 16]} justify="left">
            {/* eslint-disable-next-line no-unused-vars */}
            {[...Array(PAGE_SIZE)].map((key, index) => (
              <Col key={index} span={6}>
                <SkeletonCard />
              </Col>
            ))}
          </Row>
        )
        : error
          ? (
            <div>
              Error:
              {' '}
              {error}
            </div>
          )
          : (
            <>
              <Row gutter={[16, 16]} justify="left">
                {filteredData.map((item, index) => (
                  <Col key={index} span={6}>
                    <DesignReviewBox
                      metadata={item.extra_data}
                      onClick={() => handleReviewClick(item)}
                      reviewers={item.reviewers}
                      currentUser={currentUser}
                    />
                  </Col>
                ))}
              </Row>

              <Row justify="center" className={styles['pagination-wrapper']}>
                <Pagination
                  current={currentPage}
                  total={totalItems}
                  pageSize={PAGE_SIZE}
                  onChange={handlePageChange}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  showSizeChanger={false}
                  hideOnSinglePage
                />
              </Row>
            </>
          )}
    </div>
  );
};
